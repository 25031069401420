import { TTodoList } from 'models/employee/todo.model';
import { clsxm } from 'utils/clsxm';
import { formatDate } from 'utils/date';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ToggleList from './toggle-list';

type Value = boolean | null;

type Props = {
  disabled?: boolean;
  value?: Value;
  item?: any; // TODO update type
  todoList?: TTodoList;
  displayable?: boolean;
  onChange?: (value: Value) => void;
};

const CheckMarkButton = ({ disabled, value, item, todoList, displayable, onChange }: Props) => {
  const classes = useStyles();
  const { prompt } = item || {};
  const { orgRoles, users: assignees, author, displayAt, important } = todoList || {};
  const isDisabled = !displayable || disabled;

  const handleComplete = (__: unknown, checked: boolean) => {
    if (displayable) {
      onChange?.(checked);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <FormControlLabel
        className={clsxm('flex-1 flex', isDisabled && classes.notAllow)}
        checked={!!value}
        classes={{ disabled: classes.disabled }}
        control={
          <Checkbox
            disabled={isDisabled}
            className={clsxm(isDisabled && classes.notAllow)}
            icon={<RadioButtonUncheckedIcon color={!isDisabled ? 'primary' : 'disabled'} />}
            checkedIcon={<CheckCircleIcon color={!isDisabled ? 'primary' : 'disabled'} />}
            onChange={handleComplete}
          />
        }
        onChange={handleComplete}
        label={
          <div className="pl-2">
            <div className="flex items-center">
              <Typography className="text-11 font-600">{author.fullName}</Typography>
              <div className="w-2 h-2 mx-8 rounded-full bg-secondaryMain" />
              <Typography className="text-11 font-500">
                {formatDate(displayAt, 'HH:mm')} - {formatDate(displayAt, 'DD/MM/YYYY')}
              </Typography>
            </div>
            <div>
              <Typography
                className={clsxm('text-13 font-600', !!value && 'line-through font-400')}
                component="span"
              >
                {prompt}
              </Typography>

              {/* Roles */}
              <ToggleList
                data={orgRoles || []}
                keyExtractor={role => `${role.externalId}`}
                limit={3}
                renderItem={role => (
                  <Typography
                    color="primary"
                    className="inline text-13 font-600"
                    component="span"
                  >
                    &nbsp;#{role.name}
                  </Typography>
                )}
                icon={
                  <HowToRegOutlinedIcon
                    fontSize="small"
                    className="mb-4"
                  />
                }
                sign="#"
                getItemName={role => role.name}
              />

              {/* Assignees */}
              <ToggleList
                data={assignees || []}
                keyExtractor={assignee => `${assignee.id}`}
                limit={3}
                renderItem={assignee => (
                  <Typography
                    color="primary"
                    className="inline text-13 font-600"
                    component="span"
                  >
                    &nbsp;@{assignee.fullName}
                  </Typography>
                )}
                icon={
                  <PeopleOutlinedIcon
                    fontSize="small"
                    className="mb-4"
                  />
                }
                sign="@"
                getItemName={assignee => assignee.fullName}
              />
            </div>
          </div>
        }
      />
      <div className="flex items-center gap-8">{important && <StarRoundedIcon className="text-warning text-20" />}</div>
    </div>
  );
};

export default CheckMarkButton;

const useStyles = makeStyles({
  notAllow: {
    cursor: 'not-allowed !important',
  },
  disabled: {
    color: 'inherit !important',
  },
});
