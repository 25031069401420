import { CheckMarkButton, QuestionLayout } from 'app/components/cores/todos/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TTodoResponse } from 'models/employee/todo.model';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/date';

import _ from '@lodash';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: string;
  responseId: number;
  response?: TTodoResponse;
  displayable?: boolean;
};

function CheckMarkResponse({ name, readOnly, listId, responseId, response, displayable }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const user = useSelector(({ auth }: any) => auth.user);

  const generateTmpResponseData = (nextAnswer: string) => ({
    answer: nextAnswer,
    user: {
      fullName: user?.data.displayName,
    },
    submittedAt: formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
  });

  const handleAnswerChange = (nextAnswer: boolean | null) => {
    const answerString = nextAnswer === true ? 'yes' : 'no';
    const tmpResponse = generateTmpResponseData(answerString);
    onChange({ ...value, ...tmpResponse });

    const answerPayload = generateTaskAnswerPayload(answerString);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        showError('Could not submit your answer');
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
        const tmpResponse = _.pick(response, ['answer', 'user', 'submittedAt']);
        onChange({ ...value, ...tmpResponse });
      });
  };

  return (
    <QuestionLayout
      task={value}
      isLoading={isLoading}
    >
      <CheckMarkButton
        disabled={readOnly}
        value={value?.answer === 'yes'}
        item={response?.item || {}}
        todoList={response?.todoList}
        displayable={displayable}
        onChange={handleAnswerChange}
      />
    </QuestionLayout>
  );
}

export default CheckMarkResponse;
