import { TResponse } from 'models';
import { PropsWithChildren } from 'react';
import { formatDate } from 'utils/date';

import { CircularProgress, Typography } from '@mui/material';

type Props = {
  task?: Omit<TResponse, 'item'>;
  isLoading?: boolean;
  responseId?: number;
};

const QuestionLayout = ({ children, task, isLoading, responseId }: PropsWithChildren<Props>) => {
  const { user: completedBy, answer, submittedAt } = task || {};

  return (
    <div
      className="flex"
      data-responseid={responseId}
    >
      <div className="flex-1 px-16 md:px-32">
        {/* Start of answer */}
        <div>{children}</div>
        {/* End of answer */}

        {/* Start of Status */}
        {answer === 'yes' && (
          <div className="flex items-center ml-32">
            <Typography className="text-11 font-500 text-secondaryLight">Completed by</Typography>
            <Typography className="ml-4 text-11 font-600 text-secondaryLight">{completedBy?.fullName}</Typography>
            <div className="w-2 h-2 mx-8 rounded-full bg-secondaryLight" />
            {!!submittedAt && (
              <Typography className="text-11 font-500 text-secondaryLight">
                {formatDate(submittedAt, 'HH:mm')} - {formatDate(submittedAt, 'DD/MM/YYYY')}
              </Typography>
            )}
          </div>
        )}
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center mt-0 bg-white bg-opacity-25 z-99">
          <CircularProgress
            size={24}
            sx={{
              color: 'primary.main',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionLayout;
